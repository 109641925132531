let BASE_API_URL = 'https://apiaws.selfserviceteam.com/api';
if (process.env.NODE_ENV === 'development') {
  BASE_API_URL = 'http://localhost:8080/api';
}
export default BASE_API_URL;
export const DATE_FORMAT = 'DD/MM/YYYY';

export const MATERIAL_ACTIVITIES = {
  ACTIVITY: 'activity',
  SUB_ACTIVITY: 'subActivity',
  MATERIAL_NAME: 'materialName',
  MATERIAL_BRAND: 'materialBrand',
  MATERIAL_MODEL: 'materialModel',
  MATERIAL_SPEC: 'materialSpec',
  MATERIAL_MADE: 'materialMade',
  MATERIAL_PACK: 'materialPackage',
  MATERIAL_UNIT: 'materialUnit',
};

export const GROUP_MENU_IDS = [2];

export const SEASON = {
  1: 'Regular',
  2: 'Seasonal',
  3: 'Clearance',
};

export const REQUEST_TYPE = {
  SENT: 'Sent',
  RECEIVED: 'Received',
};

export const REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};
